import React, { useState, useEffect, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { getJSON } from '@folklore/fetch';
import { useUrlGenerator } from '@folklore/react-container';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/pages/results.scss';

const propTypes = {
    event: AppPropTypes.event.isRequired,
};

const ResultsPage = ({ event }) => {
    const urlGenerator = useUrlGenerator();
    const [request, setRequest] = useState({
        loading: false,
        page: 1,
        lastPage: -1,
        items: [],
    });
    const { loading, items, page: currentPage, lastPage } = request;
    const loadPage = page => {
        const url = `${urlGenerator.route('results.bellcenter.json', {
            event: event.id,
        })}?page=${page}`;
        setRequest({
            ...request,
            loading: true,
            page,
        });
        getJSON(url).then(({ data, meta: { last_page: newLastPage } }) =>
            setRequest({
                ...request,
                page,
                loading: false,
                items: [...items, ...data],
                lastPage: newLastPage,
            }),
        );
    };
    const loadNextPage = () => {
        if (currentPage === lastPage) {
            return;
        }
        loadPage(currentPage + 1);
    };
    useEffect(() => {
        loadPage(1);
    }, []);
    const eventDate = useMemo(
        () =>
            dayjs(event.startDate)
                .locale('fr')
                .format('dddd D MMMM YYYY'),
        [event],
    );
    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <h2 className="text-center">{eventDate}</h2>
                <div className="table-responsive">
                    <table width="100%" cellSpacing="0" cellPadding="0" className="table table-sm">
                        <thead className="thead-dark">
                            <tr>
                                <th>Nom</th>
                                <th width="150" className="text-center">
                                    Téléphone
                                </th>
                                <th width="100" className="text-center">
                                    Section
                                </th>
                                <th width="100" className="text-center">
                                    Siège
                                </th>
                                <th width="100">
                                    Message&nbsp;complet
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(({ id, name, phone, section, seat, winner, message }) => (
                                <tr key={`row-${id}`} className={winner ? 'table-success' : null}>
                                    <td>{name}</td>
                                    <td align="center">{phone}</td>
                                    <td align="center">{section}</td>
                                    <td align="center">{seat}</td>
                                    <td align="left"><div className={styles.message}>{message}</div></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {loading ? <p className="text-center">Chargement...</p> : null}
                {lastPage !== -1 && currentPage < lastPage ? (
                    <p className="text-center">
                        <button
                            type="button"
                            className="btn btn-outline-dark btn-sm"
                            onClick={loadNextPage}
                        >
                            Charger plus de participants...
                        </button>
                    </p>
                ) : null}
            </div>
        </div>
    );
};

ResultsPage.propTypes = propTypes;

export default ResultsPage;
